import { useRef, useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import Arrow from "../../components/icons/arrow";
import AlertModal from "../../components/alertModal";

import { selectUser } from "../../actions/profile";
import { getFilteredDataBarsGraph, createFavorite, destroyFavorite, createNote, destroyNote, getDashboardContent } from "../../actions/dashboard";
import { getRecentOrdersActivities } from "../../actions/balance";
import { postCleanNotificationsAdeppar } from '../../actions/financial_statements'
import DashboardTableHeaderRow from "./dashboardTableHeaderRow";
import DashboardTableBodyRow from "./dashboardTableBodyRow";
import DashboardTableBodyRowContent from './dashboardTableBodyRowContent'

let counter = 0

const DashboardTable = (props) => {
  const { data: tableDataProp, tooltips, headers } = props;

  const [filteredDataBarsGraph, setFilteredDataBarsGraph] = useState({});
  const [financialAssetEvolution, setFinancialAssetEvolution] = useState("consolidated");
  const [filtersInternalization, setFiltersInternalization] = useState({});
  const [netWorthEvolutionLoading, setNetWorthEvolutionLoading] = useState(false);
  const [tableData, setTableData] = useState(tableDataProp);
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1); // Track which row's graph is selected
  const [currentUser, setCurrentUser] = useState({});
  const [userForChange, setUserForChange] = useState({});
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [storedData, setStoredData] = useState([]);

  const tableWrapperRef = useRef(null)
  const tableRef = useRef(null)
  const tableHeaderRowRef = useRef(null)
  const rowRef = useRef([])
  const rowContentRef = useRef([])
  // const rowContentRef = useRef(null)
  const rowChevronRef = useRef([])
  const cellHeaderRef = useRef([])
  const starRef = useRef([])
  const notesRef = useRef('')

  useEffect(() => {
    props.getRecentOrdersActivities(
      props.authentication_token,
      "?page=1",
      (responseJSON) => successRecentOrderesActivity(responseJSON),
      () => tableRecentOrdersError(),
    );
  }, []);

  // Data content
  const getContentData = (id) => {
    // if theres's no data in storedData, fetch it
    if (storedData.length === 0) {
      fetchDashboardContent(id)
      return false
    }

    // get the stored data
    const storedDataArray = storedData.find(data => Object.keys(data).includes(id))
    if (!storedDataArray) {
      fetchDashboardContent(id)
    } 
  }

  const fetchDashboardContent = (id) => {
    props.getDashboardContent(
      props.authentication_token, 
      id, 
      (data)=> saveDashboardContent(data, id),
      ()=> console.log('Fetching error'),
    )
  }

  const saveDashboardContent = (data, id) => {
    console.log("DATA CONTENT", data)
    setStoredData([...storedData, {[id]: data}])
  }

  // Store scroll listener references using useRef to persist them across renders
  const wrapperScrollListenerRef = useRef(null);
  const windowScrollListenerRef = useRef(null);

  useEffect(() => {

    // Deferring cloneTableHeader execution
    setTimeout(() => {
      cloneTableHeader({ resize: false })
    }, 1000)

    const handleTableHeaderRowBehaviorListener = () => cloneTableHeader({ resize: true })

    window.addEventListener( "resize", handleTableHeaderRowBehaviorListener )

    // Cleanup
    return () =>  {
      window.removeEventListener( "resize", handleTableHeaderRowBehaviorListener )
      window.removeEventListener("scroll", windowScrollListenerRef.current);

      if(tableWrapperRef.current) {
        tableWrapperRef.current.removeEventListener("scroll", wrapperScrollListenerRef.current);
      }

      let existingClone = document.querySelector('.is-cloned');
      if (existingClone) {
        document.body.removeChild(existingClone);
      }
    }
  }, [tableWrapperRef, tableRef])


  const cloneTableHeader =  ({ resize }) => {
    const table = tableRef.current;

    // Only proceed if the table and wrapper exist
    if (table && tableWrapperRef.current) {
      const wrapper = tableWrapperRef.current;

      // Check if a clone already exists to prevent multiple clones
      let existingClone = document.querySelector('.is-cloned');

      if(existingClone) {
        if (resize) {
          existingClone.style.left = `${wrapper.getBoundingClientRect().left}px`;
        }
      }

      if (!existingClone) {
        const wrapperClone = wrapper.cloneNode(true);

        // Remove body from cloned wrapper
        const bodyClone = wrapperClone.querySelector(".c-table-dashboard__body");
        bodyClone.remove();

        wrapperClone.classList.add("is-cloned")

        wrapperClone.style.width = `${wrapper.offsetWidth}px`;
        wrapperClone.style.left = `${wrapper.getBoundingClientRect().left}px`;

        document.body.appendChild(wrapperClone);

        // Get cloned cells
        const clonedChevrons = [...wrapperClone.querySelectorAll(".c-table-dashboard__cell-sort")];
        const clonedTooltips = [...wrapperClone.querySelectorAll(".c-table-dashboard__cell-tooltip")];

        // Attach event to cloned cells
        tableData && tableData.length > 0 && Object.keys(tableData[0]).forEach((item, index) => {

          // Chevrons to order
          // clonedChevrons.forEach(el => {
          //   // We only attach the event when the cloned cell matches the original one
          //   if (el.dataset.item === item) {
          //     el.addEventListener("click", (e) => handleSort(e, item, index));
          //   }
          // });

          // Show / Hide tooltips
          clonedTooltips.forEach((el, indexTooltip) => {
            if (el.dataset.item === item) {

              el.addEventListener("click", (e) =>  {
                if (el.classList.contains('opened')) {
                  // close
                  el.classList.remove("opened")
                  setTimeout(() => {
                    // Check if there aren't any tooltips open to close the parent
                    const hasTooltipOpen = clonedTooltips.some(el => el.classList.contains('opened'))
                    if (!hasTooltipOpen) {
                      wrapperClone.classList.remove("tootlip-open")
                    }
                  }, 500)
                } else {
                  // Check if there aren't any tooltips open to open the parent
                  const hasTooltipOpen = clonedTooltips.some(el => el.classList.contains('opened'))
                  if (!hasTooltipOpen) {
                    wrapperClone.classList.add("tootlip-open")
                  }
                  // open
                  el.classList.add("opened")
                }
              });
            }
          });
        });

        // Listen horizontal scroll
        const handleWrapperScrollListener = () => handleWrapperScroll(wrapper, wrapperClone); // listener to removeEventListener
        const handleWindowScrollListener = () => handleWindowScroll(table, wrapperClone); // listener to removeEventListener

        wrapper.addEventListener("scroll", handleWrapperScrollListener);
        window.addEventListener("scroll", handleWindowScrollListener);

        // Assign listeners to references to clean up when leaving
        wrapperScrollListenerRef.current = handleWrapperScrollListener;
        windowScrollListenerRef.current = handleWindowScrollListener;
      }
    }
  }

  const handleWindowScroll = (table, wrapperClone) => {
    const tooltips = [...document.querySelectorAll(".c-table-dashboard__cell-tooltip")]
    const hasTooltipOpen = tooltips.some(el => el.classList.contains('opened'))

    if (hasTooltipOpen) {
      tooltips.forEach(tooltip => tooltip.classList.remove("opened"))
    }

    const tableTop = table.getBoundingClientRect().top;
    if (tableTop <= 0.5) {
      wrapperClone.style.opacity = 1;
      wrapperClone.style.pointerEvents = 'all';
    } else {
      wrapperClone.style.opacity = 0;
      wrapperClone.style.pointerEvents = 'none';
    }
  }

  const handleWrapperScroll = (wrapper, wrapperClone) => {
    const leftPos = wrapper.scrollLeft
    wrapperClone.scrollLeft = leftPos
  }

  const successRecentOrderesActivity = (responseJSON, itemId) => {
    // if it comes from filtered
    if (itemId) {

      const shallow = JSON.parse(JSON.stringify(storedData))

      // Get item to edit
      const itemToEdit = shallow.find(obj => obj[itemId])

      if (!itemToEdit) return false

      // Edit item
      itemToEdit[itemId].activity.data.rows = responseJSON.rows

      // Update storedData
      const tempData = shallow.map(obj => {
        if (JSON.stringify(Object.keys(obj)) === JSON.stringify(Object.keys(itemToEdit))) {
          obj = itemToEdit
        }
        return obj
      })
      setStoredData(tempData)

    }

    setFiltersInternalization(responseJSON.internalization);
  };

  const tableRecentOrdersError = () => {
    console.log("TableRecentOrders error");
  };

  const filteredCall = (params, itemId) => {
    goToPage(params, itemId);
  };

  const onChangeNote = (e) => {
    notesRef.current = e.target.value
  }

  const onSaveNote = (itemId) => {
    setIsLoading(true)
    // const id = tableDataProp[index].id
    props.createNote(
      props.authentication_token,
      (result) => updateNoteInTable(itemId, notesRef.current),
      (error) => console.log("error", error),
      itemId,
      notesRef.current
    )
  }

  const onDestroyNote = (itemId) => {
    setIsLoading(true)
    // const id = tableDataProp[index].id
    props.destroyNote(
      props.authentication_token,
      (result) => updateNoteInTable(itemId, ''),
      (error) => console.log("error", error),
      itemId,
      notesRef.current
    )
  }

  const updateNoteInTable = (itemId, content) => {
    const shallow = JSON.parse(JSON.stringify(storedData))

    const itemToEdit = shallow.find(obj => obj[itemId])

    itemToEdit[itemId].notes.value = content
    notesRef.current = content

    if (shallow.length === 1) {
      setStoredData([{...itemToEdit}])
    } else {
      const tempData = shallow.map(obj => {
        if (JSON.stringify(Object.keys(obj)) === JSON.stringify(Object.keys(itemToEdit))) {
          obj = itemToEdit
        }
        return obj
      })
      setStoredData(tempData)
    }

    setIsLoading(false)
  }

  const goToPage = (params, itemId) => {
    if (params !== "") {
      params = `${params}&user_id=${itemId}`
      props.getRecentOrdersActivities(
        props.authentication_token,
        params,
        (responseJSON) => successRecentOrderesActivity(responseJSON, itemId),
        () => tableRecentOrdersError(),
      );
    }
  };

  const showCloseContent = (index) => {
    rowContentRef.current[index].classList.toggle("is-active")
    rowChevronRef.current[index].classList.toggle("is-active")
  };

  const ownershipAction = (id, url) => {
    if (url.indexOf("https://") != -1 || url.indexOf("http://") != -1) {
      window.location = url;
    }
  };

  const updateNetWorthData = (userId, index) => {
    filteredBarsFinancialAction(
      `balance_principal/net_worths?frecuency=month&times=10&user_id=${userId}&bulltick_pro=true`,
      index,
    );
  };

  const filteredBarsFinancialAction = (url, index) => {
    setNetWorthEvolutionLoading(true);
    props.getFilteredDataBarsGraph(
      props.authentication_token,
      url,
      (responseJson) => successFilteredBars(responseJson, index),
      () => errorFilteredBars(),
    );
  };

  const successFilteredBars = (data, index) => {
    setSelectedRowIndex(index);
    setFilteredDataBarsGraph(data.balance_index.bars_graphic_data);
    setNetWorthEvolutionLoading(false);
  };

  const errorFilteredBars = () => {
    console.log("filtered bars graph error");
  };

  const pieSelected = (pie, nested) => {
    console.log(pie, nested);
  };

  // const handleSort = (e, item, index) => {
  //   const isActive = e.currentTarget.classList.contains("is-active")
  //   const sortOrder = isActive ? "descending" : "ascending"
  //
  //   showCloseContent()
  //   rotateSortArrow(index, item);
  //
  //   const sortedTableData = [...tableData].sort((a, b) => {
  //     const aVal = a[item];
  //     const bVal = b[item];
  //
  //     // Handle numeric sorting
  //     if (!isNaN(aVal) && !isNaN(bVal)) {
  //       return sortOrder === 'ascending' ? Number(aVal) - Number(bVal) : Number(bVal) - Number(aVal);
  //     }
  //
  //     if (['assets', 'net_worth', 'liabilities', 'financial_assets'].includes(item)) {
  //       // Normalize dollar amounts
  //       const aValWithDollar = parseFloat(aVal.value.split("$")[1].replace(/,/g, ''));
  //       const bValWithDollar = parseFloat(bVal.value.split("$")[1].replace(/,/g, ''));
  //       return sortOrder === 'ascending' ? aValWithDollar - bValWithDollar : bValWithDollar - aValWithDollar;
  //     }
  //
  //     // Handle string sorting
  //     return sortOrder === 'ascending' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
  //   });
  //
  //   setTableData(sortedTableData);
  // };

  // const rotateSortArrow = (index, item) => {
  //   const allArrows = [...document.querySelectorAll('.c-table-dashboard__cell-sort')]
  //   allArrows.forEach(el => {
  //     if(el.dataset.item === item) {
  //       el.classList.toggle("is-active")
  //     } else {
  //       el.classList.remove("is-active")
  //     }
  //   })
  // }

  const handleChangeUser = (e, item) => {
    if(!e.detail || e.detail == 1) {
      const elStyles = getComputedStyle(e.target)
      const elFilter = elStyles.getPropertyValue('filter') 
      const isBlurred = elFilter.includes('blur(4px)') || elFilter.includes('blur(0px)')

      if (isBlurred && counter === 0) {
        counter = 1
        e.target.classList.add('no-blur')
        activateTimer(e, item)
      } else {
        changeUserAction(item)
      }
    }
  }

  const changeUserAction = (item) => {
    // if(item.id == currentUser.id) return false;
    setUserForChange(item)
    setShowModalConfirmation(true)
  }

  const activateTimer = (e, item) => {
    const allUsernames = [...document.querySelectorAll('.c-table-dashboard__cell-first-content > span')]

    // Remove pointer from all users that are blurred
    allUsernames.forEach(user => {
      user.style.pointerEvents = 'none'
      // if it's the user active, keep it active
      const isClicked = user.classList.contains('no-blur')
      if (isClicked) user.style.pointerEvents = 'all'
    })

    const el = e.target
    // activate timer
    const interval = setInterval(() => {
      if (counter >= 10) {
        //stop counter and reset blur
        clearInterval(interval)
        counter = 0
        el.classList.remove('no-blur')
        // Activate pointer to all users
        allUsernames.forEach(user => {
          user.style.pointerEvents = 'all'
        })
      } else {
        counter++
      }
    }, 1000)
  }

  const changeUser = (usedId) => {
    props.selectUser(
      props.authentication_token,
      () => changeUserSuccess(),
      () => console.log("error"),
       userForChange.id
    );
  }

  const changeUserSuccess = () => {
    window.location.href = "/"
    setShowModalConfirmation(false)
  }

  useEffect(() => {
    const currentUser = props.assigned_users.find(user => user.current)
    setCurrentUser(currentUser)
  }, [])

  const handleFavorite = (item, index) => {
     starRef.current[index].classList.add("is-loading")

    if(item.is_favorite) {
      props.destroyFavorite(
        props.authentication_token, 
        (data) => destroyFavoriteSuccess(data, index), 
        (error) => destroyFavoriteError(error, index),
        item.id
      )
    } else {
      props.createFavorite(
        props.authentication_token, 
        (data) => createFavoriteSuccess(data, index), 
        (error) => createFavoriteError(error, index),
        item.id
      )
    }
  }

  const createFavoriteSuccess = (data, index) => {
    setTableData([...data.clients_table]);
    starRef.current[index].classList.remove("is-loading")
  } 

  const createFavoriteError = (error, index) => {
    console.log("Create favorite error", error)
    starRef.current[index].classList.remove("is-loading")
  } 

  const destroyFavoriteSuccess = (data, index) => {
    setTableData([...data.clients_table]);
     starRef.current[index].classList.remove("is-loading")
  } 

  const destroyFavoriteError = (error, index) => {
    console.log("Destroy favorite error", error)
    starRef.current[index].classList.remove("is-loading")
  } 

  return (
    <>
      <div className="c-table-dashboard__wrapper" key={"table-dashboard"} ref={tableWrapperRef}>
        <div className="c-table-dashboard" ref={tableRef}>

          {/* Header */}
          <div className="c-table-dashboard__header">
            <div className={`c-table-dashboard__row ${props.user.client_pro ? 'is-eight' : ''}`} ref={tableHeaderRowRef}>
              {tableData && tableData.length > 0 && Object.keys(tableData[0]).map((item, index) => {
                if (item !== "content" && item !== 'id' && index !== 0) {
                  return (
                    <DashboardTableHeaderRow 
                      key={`${index}-header-row`}
                      ref={(el) => cellHeaderRef.current[index] = el} 
                      item={item} 
                      headers={headers}
                      index={index} 
                      // handleSort={handleSort}
                      tooltips={tooltips}
                    />
                  );
                }
              })}
            </div>
          </div>

          {/* Body */}
          <div className="c-table-dashboard__body">
            {tableData && tableData.length > 0 && tableData.map((item, indexRow) => {
              return (
                <Fragment key={`${indexRow}-table-row-content`}>

                  {/* Row */}
                  <DashboardTableBodyRow 
                    key={`${indexRow}-row`}
                    ref={(el) => {
                      if (el) {
                        rowRef.current[indexRow] = el.row
                        rowChevronRef.current[indexRow] = el.chevron
                        starRef.current[indexRow] = el.star
                      }
                    }}
                    indexRow={indexRow}
                    item={item}
                    is_client={props.user.client_pro}
                    showCloseContent={ (indexRow) => showCloseContent(indexRow) }
                    setSelectedRowIndex={(indexRow) => setSelectedRowIndex(indexRow)}
                    updateNetWorthData={(userId, index) => updateNetWorthData(userId, index)}
                    currentUser={currentUser}
                    handleChangeUser={(e, item) => handleChangeUser(e, item)}
                    handleFavorite={(item, index) => handleFavorite(item, index)}
                    getContentData={(userId) => {
                      getContentData(userId)
                    }}
                  />

                  <DashboardTableBodyRowContent 
                    key={`${indexRow}-row-content`}
                    ref={el => rowContentRef.current[indexRow] = el}
                    // item={item}
                    indexRow={indexRow}
                    itemId={item.id}
                    netWorthEvolutionLoading={netWorthEvolutionLoading}
                    selectedRowIndex={selectedRowIndex}
                    filteredDataBarsGraph={filteredDataBarsGraph}
                    filteredBarsFinancialAction={filteredBarsFinancialAction}
                    financialAssetEvolution={financialAssetEvolution}
                    setFinancialAssetEvolution={(text) => setFinancialAssetEvolution(text)}
                    filtersInternalization={filtersInternalization}
                    filteredCall={(params, itemId) => filteredCall(params, itemId)}
                    ownershipAction={(id, url) => ownershipAction(id, url)}
                    pieSelected={(pie) => pieSelected(pie)}
                    onChangeNote={(e) => onChangeNote(e)}
                    onDestroyNote={(itemId) => onDestroyNote(itemId)}
                    onSaveNote={(itemId) => onSaveNote(itemId)}
                    isLoading={isLoading}
                    user={props.user}
                    internalization={props.internalization}
                    location={props.location}
                    history={props.history}
                    storedData={storedData}
                  /> 

                </Fragment>
              );
            })}

          </div>
        </div>
      </div>
      {showModalConfirmation && (
        <AlertModal
          isShowed={showModalConfirmation}
          title={`The user will be changed to:`}
          titleRegular={true}
          msg={userForChange.client}
          msgBold={true}
          action={changeUser}
          textButton={"Confirm"}
          textButtonAlertColor={false}
          cancelButton={true}
          cancelTextButton={"Cancel"}
          cancelButtonAlertColor={true}
          actionCancelButton={() => setShowModalConfirmation(false)}
          maxHeight={'auto'}
        />
      )}

    </>
  );
};

function mapStateToProps(state) {
  return {
    authentication_token: state.session.authentication_token,
    user: state.session.user,
    internalization: state.session.internalization,
    assigned_users: state.session.assigned_users
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFilteredDataBarsGraph: (authentication_token, url, success, error) =>
      dispatch(
        getFilteredDataBarsGraph(authentication_token, url, success, error),
      ),
    getRecentOrdersActivities: (
      authentication_token,
      page,
      url,
      success,
      error,
    ) => 
      dispatch(
        getRecentOrdersActivities(
          authentication_token,
          page,
          url,
          success,
          error,
        ),
      ),
    selectUser: (authentication_token, success, error, id) =>
      dispatch(selectUser(authentication_token, success, error, id)),
    postCleanNotificationsAdeppar: (authentication_token) => dispatch(postCleanNotificationsAdeppar(authentication_token)),
    createFavorite: (authentication_token, success, error, id) => dispatch(createFavorite(authentication_token, success, error, id)),
    destroyFavorite: (authentication_token, success, error, id) => dispatch(destroyFavorite(authentication_token, success, error, id)),
    createNote: (authentication_token, success, error, id, notes) => dispatch(createNote(authentication_token, success, error, id, notes)),
    destroyNote: (authentication_token, success, error, id, notes) => dispatch(destroyNote(authentication_token, success, error, id, notes)),
    getDashboardContent: (authentication_token, success, error) =>
      dispatch(getDashboardContent(authentication_token, success, error)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardTable);
