import { HOST_URL, URL_VERSION } from "../constants";

import get_header from "./api_header";
import { parseHeaders } from "./api_header";


export function getDashboardSuccess(payload) {
  return {
    type: "GET_DASHBOARD",
    payload,
  };
}

export function setRedirectionPro(status) {
  return {
    type: "SET_REDIRECTION",
    status,
  };
}

export function createNote(authentication_token, success, error, id, notes) {
  return async (dispatch) => {
    try {
      const url = `${HOST_URL}${URL_VERSION}balance_principal/bulltick_pro/notes`
      const params = {
        method: "POST",
        headers: get_header(authentication_token),
        body: JSON.stringify({ 
          id: id, 
          notes: notes
        })
      }

      const response = await fetch(url, params)

      await parseHeaders(response, dispatch) 

      const result = await response.json()

      if(response.ok) {
        success(result)
        return response
      } else if (response.status !== 401) {
        console.log("Error");
      }

    } catch (error) {
      throw(error)
    }
  }
}

export function destroyNote(authentication_token, success, error, id, notes) {
  return async (dispatch) => {
    try {
      const url = `${HOST_URL}${URL_VERSION}balance_principal/bulltick_pro/destroy_notes`
      const params = {
        method: "POST",
        headers: get_header(authentication_token),
        body: JSON.stringify({ 
          id: id, 
          notes: notes
        })
      }

      const response = await fetch(url, params)

      await parseHeaders(response, dispatch) 

      const result = await response.json()

      if(response.ok) {
        success(result)
        return response
      } else if (response.status !== 401) {
        console.log("Error");
      }

    } catch (error) {
      throw(error)
    }
  }
}

export function createFavorite(authentication_token, success, error, id) {
  return (dispatch) => {
    const url = `${HOST_URL}${URL_VERSION}balance_principal/bulltick_pro/favorites`
    const params = {
      method: "POST",
      headers: get_header(authentication_token),
      body: JSON.stringify({ user_favorite_id: id })
    }
    return fetch(url, params)
      .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            success(data)
            return data;
          });
        } else if (response.status !== 401) {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function destroyFavorite (authentication_token, success, error, id) {
  return (dispatch) => {
    const url = `${HOST_URL}${URL_VERSION}balance_principal/bulltick_pro/destroy_favorites`
    const params = {
      method: "POST",
      headers: get_header(authentication_token),
      body: JSON.stringify({ user_favorite_id: id })
    }
    return fetch(url, params)
      .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            success(data)
            return data;
          });
        } else if (response.status !== 401) {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

}


export function getDashboardContent(authentication_token, id, success, error) {
  return (dispatch) => {
    return fetch(`${HOST_URL}api/v1/balance_principal/bulltick_pro/get_content/${id}`, {
      method: "GET",
      headers: get_header(authentication_token),
    })
      .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            success(data)
          });
        } else if (response.status !== 401) {
          console.log("Error");
          error()
        }
      })
      .catch((error) => {
        console.log(error);
      });

  } 
}


export function getDashboard(authentication_token, success, error) {
  return (dispatch) => {
    return fetch(`${HOST_URL}api/v1/balance_principal/bulltick_pro`, {
      method: "GET",
      headers: get_header(authentication_token),
    })
      .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            dispatch(getDashboardSuccess(data));
            return data;
          });
        } else if (response.status !== 401) {
          console.log("Error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getFilteredDataBarsGraph(authentication_token, url, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL + URL_VERSION + url, {
      method: "GET",
      headers: get_header(authentication_token),
    })
      .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          response.json().then((responseJson) => {
            success(responseJson);
          });
        } else if (response.status !== 401) {
          error();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
}

export function setDefaultRedirection(authentication_token, status, success, error) {
  return (dispatch) => {
    const url = `${HOST_URL}${URL_VERSION}balance_principal/bulltick_pro/pro_redirect_default`
    const params = {
      method: "POST",
      headers: get_header(authentication_token),
      body: JSON.stringify({ 
          changed: status
        })
    }
    return fetch(url, params)
      .then((response) => parseHeaders(response, dispatch))
      .then((response) => {
        if (response.ok) {
          dispatch(setRedirectionPro(status))
          success()
        } else if (response.status !== 401) {
          error();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}


