import { HOST_URL } from "../constants";

import get_header from "./api_header";
import {parseHeaders} from './api_header'

//STORES
export function setSession(session) {
  return {
    type: "SET_SESSION",
    session
  };
}

export function destroySessionSuccess() {
  //clean all stores
  return {
    type: "DESTROY_SESSION"
  };
}

export function setProfile(profile) {
  //clean all stores
  return {
    type: "SET_PROFILE",
    profile
  };
}

export function setProfileCM(cm) {
  //clean all stores
  return {
    type: "SET_PROFILE_CM",
    cm
  };
}

export function setCurrentBreadcrumb(current_breadcrumb) {
  //clean all stores
  return {
    type: "SET_CURRENT_BREADCRUMB",
    current_breadcrumb
  };
}

export function setEnabledAssignContacts() {
  return {
      type: 'ENABLED_ASSIGN_CONTACTS',
  }
}

export function setEnabledAssignDocuments() {
  return {
      type: 'ENABLED_ASSIGN_DOCUMENTS',
  }
}

export function cleanAlertPending() {
  return {
      type: 'CLEAR_PENDING_ALERTS',
  }
}



//API

export function createSession(attributes, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "api/v1/sessions", {
      method: "POST",
      headers: get_header(""),
      body: JSON.stringify({
        email: attributes.email.trim(),
        password: attributes.password.trim()
      })
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(setSession(responseJson));
            if(attributes.remember_me){
              localStorage.setItem("authentication_token", responseJson.auth_token);
            }
            else{
              sessionStorage.setItem("authentication_token", responseJson.auth_token);
            }            
            success(responseJson);
          });
        } else {
          response.json().then(responseJson => {
            error(responseJson);
          });
          
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function autoLogin(authentication_token, success, error) {
  return dispatch => {
    return (
      fetch(HOST_URL + "api/v1/sessions", {
        method: "GET",
        headers: get_header(authentication_token)
      })
        
        .then((response) => parseHeaders(response, dispatch))// .then(updateGroupConfig)
        .then(response => {
          if (response.ok) {
            response.json().then(responseJson => {
              dispatch(setSession(responseJson));
              success();
            });
          } else {
            error();
          }
        })
        .catch(error => {
          console.log("autoLogin");
          console.error(error);
        })
    );
  };
}

export function destroySession(authentication_token, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "api/v1/sessions", {
      method: "DELETE",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          dispatch(destroySessionSuccess());
          success();
        } else {
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function updateUser(authentication_token, attributes, success, error) {

  return dispatch => {
    return fetch(HOST_URL + "api/v1/profile", {
      method: "PUT",
      headers: get_header(authentication_token),
      body: JSON.stringify(attributes)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(setSession(responseJson));
            // dispatch(setProfile(responseJson.profile));
            success();
          });
        } else {
          response.json().then(responseJson => {
            error(responseJson);
          });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getUser(authentication_token, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "api/v1/profile", {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(setProfile(responseJson));
            // console.log("responseJson", responseJson);
            success();
          });
        } else {
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function getProfileCM(authentication_token, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "api/v1/profile/content_manager", {
      method: "GET",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            dispatch(setProfileCM(responseJson.cm))
            success(responseJson);
          });
        } else {
          error();
        }
      })
      .catch(error => {
        console.error(error);
      });
  };
}


export function createUserInvitation(user, authentication_token, success, error) {
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/profile', {
      method: 'POST',
      headers: get_header(authentication_token),
      body: JSON.stringify({user})
    })
    .then((response) => {
      if (response.ok) {
        response.json().then((responseJson) => {                    
          success(responseJson) 
        })
        
      }
      else{
        error()
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function deleteAccount(id, authentication_token, success, error) {
  
  return (dispatch) => {
    return fetch(HOST_URL+'api/v1/profile/delete_account/'+id, {
      method: 'DELETE',
      headers: get_header(authentication_token),
    })
    .then((response) => parseHeaders(response, dispatch))
    .then((response) => {
      if (response.ok) {
        
        response.json().then((responseJson) => {
          success()          
        })
      }
      else{
        error();
      }

    })
    .catch((error) => {
      console.error(error);
    });
  }
}

export function createSessionUniken(email, geolocation, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "uniken/sessions", {
      method: "POST",
      headers: get_header(""),
      body: JSON.stringify({
        email: email.trim(),
        geolocation: geolocation
      })
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {      
            success(responseJson.uuid);
          });
        } else {
          if(response.status === 404){
            error({email_uniken:'not_found'})
          }
          else{
            response.json().then(responseJson => {
              error(responseJson);
            });  
          }
        }
      })
      .catch(error => {
        // error({email:'not_found'})
        console.log(error);
      });
  };
}

export function checkNotificationStatus(uuid, user_name, remember_me, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "uniken/check_status/"+uuid+"?user_name="+user_name, {
      method: "GET",
      headers: get_header("")
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {
            if(responseJson.session !== null){
              dispatch(setSession(responseJson.session));
              if(remember_me){
                localStorage.setItem("authentication_token", responseJson.session.auth_token);
              }
              else{
                sessionStorage.setItem("authentication_token", responseJson.session.auth_token);
              }   
            }
            success(responseJson);
          });
        } else {
          response.json().then(responseJson => {
            error(responseJson);
          });
          
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
}

export function profileAuthorization(authentication_token, success, error) {
  return dispatch => {
    return fetch(HOST_URL + "api/v1/profile/profile_authorization", {
      method: "POST",
      headers: get_header(authentication_token)
    })
    .then((response) => parseHeaders(response, dispatch))
      .then(response => {
        if (response.ok) {
          response.json().then(responseJson => {   
            success(responseJson.uuid);
          });
        } else {
          response.json().then(responseJson => {
            error(responseJson);
          });
          
        }
      })
      .catch(error => {
        error({})
        console.log(error);
      });
  };
}
