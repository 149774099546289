import { useRef, useEffect } from "react";
import PermissionCircle from "./permissionCircle";
import Icon from "../../components/icons";

const HeroFinancialCard = (props) => {
  const { item, setTooltip, tooltip_enabled } = props

  const heroCardRef = useRef(null);

  return (
    <div 
      ref={heroCardRef}
      className="c-dashboard-hero__financial-card"
    >
      {/* Tooltip button */}
      {tooltip_enabled && 
        <div 
          className="c-dashboard-hero__financial-info"
          onClick={() => setTooltip(item, heroCardRef)}
        >
          {Object.values(item)[0].hasOwnProperty("see_actives_permission") && (
            <Icon name="InfoTooltip" noWrapper={true} />
          )}
        </div>
      }
      

      <p className="c-dashboard-hero__financial-number">
        {Object.values(item)[0].amount}
      </p>

      <p className="c-dashboard-hero__financial-text small o-color--blue-dark">{Object.values(item)[0].title}</p>
    </div>
  )
}

export default HeroFinancialCard
